import { SNS_SITE } from '@/constants/common'

export const jpAlumniProfile = {
  SD40pleasePass: 'パスワードの変更は',
  SD40pleaseEmail: 'メールアドレスの変更は',
  SD40reset: 'こちら',
  SD40here: 'から行ってください。',
  SD40editEmail: 'メールを編集する',
  SD40editEmailConfirm: 'メールを編集して確認する',
  SD40editEmailComplete: 'メール編集完了',
  SD40editPassword: 'メールのパスワード',
  SD40editPasswordConfirm: 'メールパスワード確認',
  SD40editPasswordComplete: 'メールパスワードが完了しました',
  SD40editCompleted: 'アカウント設定完了',
  SD40editCompletedMess: 'アカウント情報を変更しました。',
  SE45resetEmail: 'メールアドレスの再設定',
  SE45description1: '入力された下記メールアドレスに確認のメールをお送りします。',
  SE45description2: 'メールアドレスに間違いがないかご確認ください。',
  SE45description3: 'クリックして、メールアドレスの受信確認を完了してください。',
  SE45emailToReset: '再設定するメールアドレス',
  SE46description1: '確認のメールを送信しました。',
  SE46description2: 'メールに記載されているURLにアクセスし、変更手続きを完了してください。',
  SE47resetPassword: 'パスワードの再設定',
  SE47description: 'パスワードの変更が完了しました。',
  SE49resetPasswordSuccess: 'パスワードのリセット成功',
  SD40currentJob: '現在のお仕事',
  SD40withdrawalService: 'サービスの退会は',
  SD40serverErrorMessage: 'アカウント編集に失敗しました。',
  SD40updateMailError: 'メールアドレス変更に失敗しました。',
  SD40updatePasswordError: 'パスワード変更に失敗しました。',
  SD40confirmUpdate: 'アカウント情報を更新します。',
  SD40Current: '職種',
  SD40currentJob2: '現職情報',
  SD40SocialLinking: 'SNS連携',
  SD40Linked: '連携',
  SD40NotLinked: '未連携',
  SD40RemoveLink: '解除',
  SD40UnlinkMessage: '%sとの連携を解除すると%s情報でログイン出来ませんのでご注意ください。',
  [`SD40${SNS_SITE.SNS_FACEBOOK}DisplayName`]: 'Facebook',
  [`SD40${SNS_SITE.SNS_GOOGLE}DisplayName`]: 'Google',
  [`SD40${SNS_SITE.SNS_LINE}DisplayName`]: 'LINE',
  SD40UnlinkConfirm: '連携解除の確認',
  SD40UnlinkCompleteMessage: '%s解除しました。',
}
