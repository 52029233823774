export const enCommunityCandidates = {
  CI10breadcrumb: 'List of alumni waiting for approval',
  CI10search: 'Search',
  CI10titleName: 'User name',
  CI10registrationDate: 'Date of application for participation',
  CI10RegistrationRoute: 'Registration route',
  CI10confirm: '%s Confirmation',
  CI10selectedUser: '%s selected users.',
  CI10updateCompleted: 'Bulk %s',
  CI40current: 'Current',
  CI40match: 'Match',
  CI10updateFailed: 'The following users could not be %s.',
  CI10ApproveError: 'Bulk approval failed.',
  CI10RejectError: 'Bulk rejection failed.',
  CI10DeleteError: 'Bulk deletion failed.',
  CI10maxUsersError: 'The maximum number of registered users has been reached.',
  CI10contactAdmin: 'Contact your community admin.',
  CI40ApprovalError: 'Approval failed.',
  CI40RejectionError: 'Rejection failed.',
  CT10url: 'URL',
  CT10siteName1: 'Site name ①',
  CT10siteUrl1: 'Site URL ①',
  CT10siteName2: 'Site name ②',
  CT10siteUrl2: 'Site URL ②',
}
