export const ReplayName = (is_deleted_in_30_days: string | number, available: boolean, family_name: string, given_name: string, user_type) => {
  if (is_deleted_in_30_days === 0) {
    if (!available) {
      return family_name + ' ' + given_name + ' ' + '[一時停止]'
    } else return family_name + ' ' + given_name
  } else if (is_deleted_in_30_days === 1 && ([1, 2].includes(user_type) || ['alumni', 'employee'].includes(user_type))) {
    return family_name + ' ' + given_name + ' ' + '[脱退済み]'
  } else if (is_deleted_in_30_days === 2 && ([1, 2].includes(user_type) || ['alumni', 'employee'].includes(user_type))) {
    return '脱退済みユーザ'
  } else {
    return family_name + ' ' + given_name
  }
}

export const ReplaceHashtag = (family_name: string, given_name: string, content: string) => {
  return content?.replaceAll('#姓#', family_name)?.replaceAll('#名#', given_name)
}

export const DisplayNamePost = (
  is_deleted_in_30_days: string | number,
  available: boolean,
  family_name: string,
  given_name: string,
  user_type: string | number,
  display_name: string,
  my_post: boolean
) => {
  if (['community_admin', 3].includes(user_type)) {
    return display_name
  }
  if ((['alumni', 2].includes(user_type) && !my_post) || (['alumni', 2, 'employee', 1].includes(user_type) && my_post && !family_name && !given_name)) {
    if (is_deleted_in_30_days === 0) {
      if (!available) {
        return display_name + ' ' + '[一時停止]'
      } else return display_name
    } else if (is_deleted_in_30_days === 1) {
      return display_name + ' ' + '[脱退済み]'
    } else if (is_deleted_in_30_days === 2) {
      return '脱退済みユーザ'
    } else {
      return display_name
    }
  }
  if (['employee', 1].includes(user_type) || (['alumni', 2].includes(user_type) && my_post && family_name && given_name)) {
    if (is_deleted_in_30_days === 0) {
      if (!available) {
        return family_name + ' ' + given_name + ' ' + '[一時停止]'
      } else return family_name + ' ' + given_name
    } else if (is_deleted_in_30_days === 1) {
      return family_name + ' ' + given_name + ' ' + '[脱退済み]'
    } else if (is_deleted_in_30_days === 2) {
      return '脱退済みユーザ'
    } else {
      return family_name + ' ' + given_name
    }
  }
}

export const DisplayNameModalLike = (
  is_deleted_in_30_days: string | number,
  available: boolean,
  family_name: string,
  given_name: string,
  user_type: string | number,
  display_name: string,
  my_like: boolean
) => {
  if (['community_admin', 3].includes(user_type)) {
    return display_name
  }
  if ((['alumni', 2].includes(user_type) && !my_like) || (['alumni', 2, 'employee', 1].includes(user_type) && my_like && !family_name && !given_name)) {
    if (is_deleted_in_30_days === 0) {
      if (!available) {
        return display_name + ' ' + '[一時停止]'
      } else return display_name
    } else if (is_deleted_in_30_days === 1) {
      return display_name + ' ' + '[脱退済み]'
    } else if (is_deleted_in_30_days === 2) {
      return '脱退済みユーザ'
    } else {
      return display_name
    }
  }
  if (['employee', 1].includes(user_type) || (['alumni', 2].includes(user_type) && my_like && family_name && given_name)) {
    if (is_deleted_in_30_days === 0) {
      if (!available) {
        return family_name + ' ' + given_name + ' ' + '[一時停止]'
      } else return family_name + ' ' + given_name
    } else if (is_deleted_in_30_days === 1) {
      return family_name + ' ' + given_name + ' ' + '[脱退済み]'
    } else if (is_deleted_in_30_days === 2) {
      return '脱退済みユーザ'
    } else {
      return family_name + ' ' + given_name
    }
  }
}

export const DisplayNameModalInterest = (
  is_deleted_in_30_days: string | number,
  available: boolean,
  family_name: string,
  given_name: string,
  user_type: string | number,
  display_name: string,
  my_interest: boolean
) => {
  if (['community_admin', 3].includes(user_type)) {
    return display_name
  }
  if ((['alumni', 2].includes(user_type) && !my_interest) || (['alumni', 2, 'employee', 1].includes(user_type) && my_interest && !family_name && !given_name)) {
    if (is_deleted_in_30_days === 0) {
      if (!available) {
        return display_name + ' ' + '[一時停止]'
      } else return display_name
    } else if (is_deleted_in_30_days === 1) {
      return display_name + ' ' + '[脱退済み]'
    } else if (is_deleted_in_30_days === 2) {
      return '脱退済みユーザ'
    } else {
      return display_name
    }
  }
  if (['employee', 1].includes(user_type) || (['alumni', 2].includes(user_type) && my_interest && family_name && given_name)) {
    if (is_deleted_in_30_days === 0) {
      if (!available) {
        return family_name + ' ' + given_name + ' ' + '[一時停止]'
      } else return family_name + ' ' + given_name
    } else if (is_deleted_in_30_days === 1) {
      return family_name + ' ' + given_name + ' ' + '[脱退済み]'
    } else if (is_deleted_in_30_days === 2) {
      return '脱退済みユーザ'
    } else {
      return family_name + ' ' + given_name
    }
  }
}

export const CountCharacter = (value: string) => {
  return value.length + value.split('\n').length - 1
}

export const CURRENCY = new Intl.NumberFormat()

export const URL_PRIVACY = 'https://www.mynavi.jp/privacy/'
export const URL_COMPANY = 'https://www.mynavi.jp/company/'
export const URL_CUSTOMER = 'https://customer-yelloop.zendesk.com/hc/ja'
export const URL_SUPPORT = 'https://support-yelloop.zendesk.com/hc/ja'

export const numberWithCommas = (x: any) => {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : x
}

export const MOBILE_WIDTH = 767.98

export enum SNS_SITE {
  SNS_GOOGLE = 'google_oauth2',
  SNS_FACEBOOK = 'facebook',
  SNS_LINE = 'line',
}

export enum SNS_METHOD {
  CONNECT = 'connect',
  INVITE = 'invite',
  LOGIN = 'login',
}

export const snsConnectDefinedErrorMsg = ['ERROR_SOCIAL_ACCOUNT_LINKING', 'ERROR_SOCIAL_ACCOUNT_ALREADY_LINKING', 'ACCESS_DENIED', 'NO_AUTHORIZATION_CODE', 'ERROR_LOGIN']

export const SNS_LOGIN_URL = (snsSite: SNS_SITE) => {
  const snsMap = {
    [SNS_SITE.SNS_GOOGLE]: 'google_oauth2',
    [SNS_SITE.SNS_FACEBOOK]: 'facebook',
    [SNS_SITE.SNS_LINE]: 'line',
  }
  const snsVal = snsMap[snsSite] ?? ''
  if (!snsVal) {
    return ''
  }
  return `${process.env.VUE_APP_API_ENDPOINT}/alumnus/${snsVal}`
}

export const MODAL_ID = {
  INTEREST_COMPLETE: 'interestComplete',
  INTEREST_CONFIRM: 'interestConfirm',
}
