export const jpCommunityCandidates = {
  CI10breadcrumb: '承認待ちアルムナイ一覧',
  CI10search: '検索する',
  CI10titleName: 'ユーザ名',
  CI10registrationDate: '参加申請日時',
  CI10RegistrationRoute: '登録経路',
  CI10confirm: '%s確認',
  CI10selectedUser: '選択されたユーザを%sします。',
  CI10noDataSearch: 'アルムナイユーザが見つかりませんでした。',
  CI10updateCompleted: '%sしました。',
  CI10updateCompletedApprove: '審査結果が通知されました。',
  CI12titleModal: '%s完了',
  CI12okButton: '%sする',
  CI40current: '現在',
  CI40match: '一致',
  CI10updateFailed: '以下のユーザを%sできませんでした。',
  CI40nameSchool: '在学時の氏名(漢字)',
  CI40serverError: '既にサービスを退会しているユーザです。',
  CI40breadcrumb: '承認待ちアルムナイユーザ詳細',
  CI10maxUsersError: 'ユーザ登録数の上限に達しています。',
  CI10ApproveError: '一括承認に失敗しました。',
  CI10RejectError: '一括否決に失敗しました。',
  CI10DeleteError: '一括削除に失敗しました。',
  CI10contactAdmin: 'コミュニティ管理者にお問い合わせください。',
  CT10searchNoData: '運営事務局の投稿が見つかりませんでした。',
  CI40ApprovalError: '承認に失敗しました。',
  CI40RejectionError: '否決に失敗しました。',
  CT10url: 'URL',
  CT10siteName1: 'サイト名①',
  CT10siteUrl1: 'サイトURL①',
  CT10siteName2: 'サイト名②',
  CT10siteUrl2: 'サイトURL②',
}
