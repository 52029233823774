export const jpGeneralUserCommunity = {
  OE12button1: '新規登録',
  OE12button2: 'アップロード',
  OE12userManagement: 'ユーザ一管理 ',
  OE20edit: '在籍ユーザ新規登録',
  OE20registration: '一般ユーザ新規登録',
  OE20belongs: '所属',
  OE20confirmEdit: '編集を確認',
  OE12finish: 'CE12一般ユーザ一覧 へ戻る',
  OE12status: '状態',
  OE12participating: '参加中',
  OE12fieldStatus: 'ステータス',
  OE12inUse: '利用中',
  OE12pause: '[一時停止]',
  OE12notParticipating: '未参加',
  OE31profileView: 'プロフィール',
  OE31permissionSetting: '権限設定',
  OE31registeredUser: '在籍ユーザ権限',
  OE31adminUser: '管理ユーザ権限',
  OE31editUser: '在籍ユーザ編集',
  OE80heading: 'アルムナイ候補者リストアップロード',
  OE80selectOne: '一括登録する在籍ユーザのCSVデータを選択してください。',
  OE80textValidateDuplicateUpload: '以下については重複データのため登録されませんでした。',
  OE80userList: 'ユーザ一覧（在籍ユーザ）',
  OE80uploaded: '在籍ユーザアップロード',
  OE80line: '行目：',
  OE80textSuccess1: '正常にアップロードされました。',
  OE80text2: '以下の項目の内容をご確認ください。',
  OE80text2ValidateDuplicate: '以下については重複データのため登録されませんでした。',
  OE80text3: '上記エラーのため、アップロードされませんでした。',
  OE80text4: 'アップロードエラーです。',
  OE80register: '登録',
  OE80subject: '件',
  OE80noteUploadMaxNumber: '※一度にアップロードできる上限数は100件です。', // 2022/12/7 CW-TrungNT Fix UAT#5645: OE80 (Ph2-R20221222)
  OE12addToGroup: 'グループに追加',
  OE12GroupName: 'グループ名',
  OE12inviteGroupName: '招待グループ名',
  OE12addToGroupSucceed: '「%s」へ追加しました。',
  OE12addToGroupFailed: 'グループへの追加に失敗しました。',
  OE12toEditGroup: 'グループ編集へ',
  OE12bulkInvite: '一括再招待',
  OE12titleModal: '選択されたユーザを再招待します。',
  OE12updateFailed: '利用中または一時停止のユーザが存在するため再招待できません。',
  OE12invited: '再招待しました。',
  OE31name: 'ユーザ詳細  ',
  OE31consentDate: '利用注意事項の同意日時',
  OF10confirmMessage: 'ー度興味ありをすると取り消すことができません。興味ありをしたユー ザはコミュニティ運営者のみに知らされます。',
  OF10completeMessage: '「興味あり」しました。 ※投稿者と期間限定でチャットが可能です。',
  OF10confirmTitle: '注意'
}
