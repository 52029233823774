export const enGeneralUserCommunity = {
  OE12button1: 'Sign up',
  OE12status: 'Status',
  OE12participating: 'Participating',
  OE12fieldStatus: 'Status',
  OE12inUse: 'In use',
  OE12pause: '[pause]',
  OE12notParticipating: 'Not participating',
  OE31profileView: 'Profile view',
  OE31permissionSetting: 'Permission setting',
  OE31registeredUser: 'Enrolled user authority',
  OE31adminUser: 'Administrative user rights',
  OE31editUser: 'Edited by registered users',
  OE12button2: 'アップロード',
  OE12userManagement: 'ユーザ一管理 ',
  OE20edit: '在籍ユーザ新規登録',
  OE20registration: '一般ユーザ新規登録',
  OE20belongs: '所属',
  OE20confirmEdit: '編集を確認',
  OE12finish: 'CE12一般ユーザ一覧 へ戻る',
  OE80heading: 'アルムナイ候補者リストアップロード',
  OE80selectOne: '在籍ユーザリストのファイルを選択してください。',
  OE80userList: 'ユーザ一覧（在籍ユーザ）',
  OE80uploaded: '在籍ユーザアップロード',
  OE80line: '行目：',
  OE80textSuccess1: '正常にアップロードされました。',
  OE80text2: '以下の項目の内容をご確認ください。',
  OE80text2ValidateDuplicate: 'The following was not registered due to duplicate data.',
  OE80text3: '上記エラーのため、アップロードされませんでした。',
  OE80text4: 'アップロードエラーです。',
  OE80register: '登録',
  OE80subject: '件',
  OE80required: 'ファイルを選択してください。',
  OE80textValidateDuplicateUpload: 'The following was not registered due to duplicate data.',
  OE80noteUploadMaxNumber: '*Up to 100 files can be uploaded at one time.', // 2022/12/7 CW-TrungNT Fix UAT#5645: OE80 (Ph2-R20221222)
  OE12addToGroup: 'Add to group',
  OE12inviteGroupName: 'Group name to invite',
  OE12addToGroupSucceed: 'Added user to「%s」',
  OE12addToGroupFailed: 'Failed to add user',
  OE12toEditGroup: 'Edit group',
  OF10confirmMessage: 'Once you have expressed an interest, you cannot undo it. Only the community administrator will be notified of users who have expressed an interest.',
  OF10completeMessage: 'I\'m "interested." *You can chat with the poster for a limited time.',
  OF10confirmTitle: 'Warning'
}
