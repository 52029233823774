import { mutations } from './user.mutations'
import { getters } from './user.getters'
import { actions } from './user.actions'

import { Module } from 'vuex'
import { RootState } from '@/store/store.type'
import { USER_STORE } from '@/store/constants'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'

const today = new Date()
const priorDate = new Date('January 1').setFullYear(today.getFullYear() - 30)

export const state: UserStateTypes = {
  joinUserData: {
    family_name: '',
    given_name: '',
    family_name_kana: '',
    given_name_kana: '',
    password: '',
    confirmPassword: '',
    birthday: priorDate,
    consent_of_yelloop: false,
    consent_of_community: false,
  },
  mailInvite: '',
  invitedCommunity: {},
  checkSubmitFormUser: false,
  termsYelloop: {},
  termsCommunity: {},
  first_time: {
    family_name: 'aaa',
  },
  alumniProfile: {
    family_name: '',
    given_name: '',
    family_name_kana: '',
    given_name_kana: '',
    birthday: '',
    institution: '',
    job_type_parent_id: '',
    job_type_child_id: '',
    enrollment_start_period: '',
    secondary_password: '',
    google_connected: false,
    facebook_connected: false,
    line_connected: false,
    // emailData: {
    //   email: '',
    //   emailConfirm: '',
    // },
    // changeEmail: false,
    // changePassword: false,
    // passwordData: {
    //   password: '',
    //   confirmPassword: '',
    // },
  },
}

export type UserStateTypes = {
  joinUserData: any
  checkSubmitFormUser: boolean
  invitedCommunity: any
  termsYelloop: any
  termsCommunity: any
  first_time: any
  alumniProfile: any
  mailInvite: ''
}

const user: Module<UserStateTypes, RootState> = {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default user

export interface UserGettersTypes {
  [USER_STORE.GETTERS.JOIN_USER_VALUE](state: UserStateTypes): any
  [USER_STORE.GETTERS.INVITE_COMMUNITY](state: UserStateTypes): any
}

export type UserMutationTypes<S = UserStateTypes> = {
  [USER_STORE.MUTATIONS.SET_JOIN_USER_VALUE](state: S, payload: any): void
  [USER_STORE.MUTATIONS.SET_INVITE_COMMUNITY](state: S, payload: any): void
}

export interface UserActionTypes {
  [USER_STORE.ACTIONS.SET_JOIN_USER_VALUE]({ commit }: any, payload: any): void
  [USER_STORE.ACTIONS.SET_INVITE_COMMUNITY]({ commit }: any, payload: any): void
}

export type UserModuleType<S = UserStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof UserMutationTypes, P extends Parameters<UserMutationTypes[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<UserMutationTypes[K]>
} & {
  getters: {
    [K in keyof UserGettersTypes]: ReturnType<UserGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof UserActionTypes>(key: K, payload?: Parameters<UserActionTypes[K]>[1], options?: DispatchOptions): ReturnType<UserActionTypes[K]>
}
