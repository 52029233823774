import { SNS_SITE } from '@/constants/common'

export const enAlumniProfile = {
  SD40pleasePass: 'Password reset',
  SD40pleaseEmail: 'Email address reset',
  SD40reset: 'Here',
  SD40here: 'Please go from.',
  SE45resetEmail: 'Reset your email address',
  SD40serverErrorMessage: 'Account editing failed.',
  SD40updateMailError: 'Email address change failed.',
  SD40updatePasswordError: 'Password change failed.',
  SD40confirmUpdate: 'Update your account information.',
  SD40Current: 'Occupation',
  SD40currentJob2: '現職情報',
  SD40SocialLinking: 'SNS integration',
  SD40Linked: 'Linked',
  SD40NotLinked: 'Not linked',
  SD40Unlink: 'Unlink',
  SD40UnlinkMessage: 'Please note that if you unlink your account from %s, you will not be able to log in using your %s information.',
  [`SD40${SNS_SITE.SNS_FACEBOOK}DisplayName`]: 'Facebook',
  [`SD40${SNS_SITE.SNS_GOOGLE}DisplayName`]: 'Google',
  [`SD40${SNS_SITE.SNS_LINE}DisplayName`]: 'LINE',
  SD40UnlinkConfirm: 'Confirm unlinking',
  SD40UnlinkCompleteMessage: '% has been unlinked.',
}
